<template>
    <div class="reward">
        <el-tabs v-model="appType" @tab-click="handleClick">
            <el-tab-pane name="kaola" label="考拉红包推广"></el-tab-pane>
            <el-tab-pane name="tmall" label="猫享红包推广"></el-tab-pane>
        </el-tabs>
        <Page v-if="appType === 'kaola'" appType="kaola" key="kaola" />
        <Page4Tmall v-else appType="tmall" key="tmall" />
    </div>
</template>

<script>
import Page from "../../components/redpackedPromotion/page.vue";
import Page4Tmall from "../../components/redpackedPromotion/page4Tmall.vue";

export default {
    data() {
        return { appType: "kaola" };
    },

    methods: {
        handleClick(e) {
            console.log(1111, e);
        }
    },

    components: { Page, Page4Tmall }
};
</script>
