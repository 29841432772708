<template>
    <div class="reward" v-loading="status === 'loading'">
        <error-data v-if="status === 'error'">
            请求错误, 请重试
        </error-data>
        <template v-if="status === 'success'">
            <no-data marginTop="200" v-if="!activities.length">
                暂未参加活动
            </no-data>

            <div class="reward-wrapper" v-if="activities.length">
                <div class="reward-inner">
                    <div class="reward-info">
                        <div class="reward-info-block">
                            活动名称
                            <el-select
                                class="reward-middle"
                                v-model="activityId"
                                placeholder="请选择"
                                @change="handleActivityChange()"
                            >
                                <el-option
                                    v-for="item in activities"
                                    :key="item.activityId"
                                    :label="item.activityName"
                                    :value="item.activityId"
                                />
                            </el-select>
                        </div>

                        <div
                            class="reward-info-block"
                            v-if="activity.activityStatus !== 1 && !remainTime"
                        >
                            <el-tag class="reward-middle" type="info">
                                活动时间：{{
                                    activity.startTime
                                        | formatTime("MM-dd HH:mm:ss")
                                }}
                                -
                                {{
                                    activity.endTime
                                        | formatTime("MM-dd HH:mm:ss")
                                }}
                            </el-tag>
                        </div>

                        <div
                            class="reward-info-block"
                            v-if="activity.activityStatus === 1 && !!remainTime"
                        >
                            <span class="reward-middle">距结束还剩：</span>
                            <el-tag
                                :type="remainTime.d >= 3 ? 'warning' : 'danger'"
                                class="reward-middle"
                            >
                                {{ remainTime | formatRemainTime }}
                            </el-tag>
                        </div>
                    </div>
                    <div class="reward-detail">
                        <div class="reward-amount">
                            <p class="tip">
                                红包派发个数
                            </p>
                            <p class="money">{{ activity.redpacketNum }}</p>
                        </div>

                        <div class="reward-amount un">
                            <p class="tip">
                                红包成交订单数
                            </p>
                            <p class="money">
                                {{ activity.orderNum }}
                            </p>
                        </div>

                        <div class="reward-amount un">
                            <p class="tip">
                                预估推广奖励
                            </p>
                            <p class="money">
                                <span class="symbol">&yen;</span
                                >{{ activity.amount | formatPrice }}
                            </p>
                        </div>

                        <div class="reward-amount un">
                            <p class="tip">
                                已结算奖励
                            </p>
                            <p class="money">
                                <span class="symbol">&yen;</span
                                >{{ activity.settleAmount | formatPrice }}
                            </p>
                        </div>

                        <div class="reward-detail">
                            <el-alert
                                title="此页面供您查询红包推广效果，预估红包推广总佣金仅供参考，实际数据会根据订单是否发生售后产生变化，佣金收益以最终结算为准。"
                                type="warning"
                                show-icon
                            />
                        </div>
                    </div>
                </div>
                <div style="height: 14px; background: #f5f5f5;" />

                <div class="reward-inner" style="margin-top: 14px;">
                    <el-table
                        :data="activity.orderStatList"
                        style="width: 100%"
                    >
                        <el-table-column label="奖励类型">
                            <template slot-scope="scope">
                                {{
                                    scope.row.type == 1
                                        ? "订单奖励"
                                        : "新登奖励"
                                }}
                            </template>
                        </el-table-column>
                        <el-table-column label="数量（笔）" prop="orderNum" />
                        <el-table-column label="预估奖励（元）" prop="amount" />
                        <el-table-column label="结算奖励（元）" prop="amount" />
                    </el-table>
                </div>

                <div class="reward-inner" style="margin-top: 14px;">
                    <div style="font-weight: bold;margin-bottom: 10px;">
                        活动规则：
                    </div>
                    <div class="rule" v-html="activity.regulation"></div>
                </div>
            </div>
        </template>

        <export-email-dialog
            :visible.sync="exportVisible"
            @email-value="getExportEmail"
        />
    </div>
</template>

<script>
import moment from "moment";
import Pagination from "../../components/common/pagination";
import Tooltip from "../../components/settle/tooltip";
import Countdown from "../../utils/count-down";
import NoData from "../../components/common/noData";
import ErrorData from "../../components/common/errorData";
import Level from "../../components/report/level";
import ExportEmailDialog from "../../components/settle/export-email";
import getDayEnd from "../../utils/get-day-end";
import formatPrice from "../../utils/format-price";

export default {
    data() {
        const positionOptions = [
            {
                positionId: "-1",
                name: "全部"
            }
        ];
        return {
            status: "loading",
            activities: [],
            allActivities: [],
            activity: {},
            activityId: "",
            activityType: 0,
            rangeDate: [],
            rangeDate1: [],
            rangeDate2: [],
            formData: {
                settleStatus: -1,
                positionId: positionOptions[0].positionId,
                pageIndex: 1,
                pageSize: 20
            },
            remainTime: null,
            totalCount: 0,
            exportVisible: false,
            redPackOrder: [],
            activeIndex: "1",
            settleStatuses: [
                {
                    value: -1,
                    label: "全部"
                },
                {
                    value: 0,
                    label: "待结算"
                },
                {
                    value: 1,
                    label: "已结算"
                },
                {
                    value: 2,
                    label: "已失效"
                }
            ],
            positionOptions
        };
    },

    props: {
        appType: String
    },

    components: {
        "reward-tooltip": Tooltip,
        pagination: Pagination,
        "no-data": NoData,
        "error-data": ErrorData,
        // eslint-disable-next-line vue/no-unused-components
        level: Level,
        "export-email-dialog": ExportEmailDialog
    },

    async created() {
        this.status = "loading";
        try {
            await this.getActivities();
            // await this.getRedpacketDaily();
            // await this.getRedpacketOrderDetailReport();
            await this.getCorpZhuankePromotionPosition();
        } catch (e) {
            this.status = "error";
        }
    },

    destroyed() {
        this.destroyCountDown();
    },

    methods: {
        formatOrderTime(row, column, cellValue) {
            return moment(parseInt(cellValue, 10) || Date.now()).format(
                "YYYY.MM.DD HH:mm:ss"
            );
        },
        formatPrice(row, column, cellValue) {
            return formatPrice(cellValue);
        },
        formatAmount(row, column, cellValue) {
            return `¥${formatPrice(cellValue)}`;
        },
        formatRate(row, column, cellValue) {
            return `${parseFloat(cellValue) * 100}%`;
        },
        formatSettleStatus(v) {
            return (
                {
                    0: "待结算",
                    1: "已结算",
                    2: "已失效"
                }[parseInt(v, 10)] || ""
            );
        },
        formatSettleStatusType(v) {
            return {
                0: "danger",
                1: "success",
                2: "info"
            }[parseInt(v, 10)];
        },
        //推广位获取
        async getCorpZhuankePromotionPosition() {
            const { appType } = this;
            const data = await this.$request({
                url: "/api/promotion/getCorpZhuankePromotionPosition",
                params: { appType }
            });
            this.positionOptions = [...this.positionOptions, ...(data || [])];
        },
        // eslint-disable-next-line
        handleSelect(key, keyPath) {
            this.activeIndex = key;
            this.rangeDate = [];
        },
        /* eslint-disable */
        handleRangeChange(v) {
            const s = +new Date(v[0]);
            const e = getDayEnd(+new Date(v[1]));
            if (e - s > 1000 * 60 * 60 * 24 * 30) {
                this.$message.error(
                    "您选择的时间区间过长, 最多选择一个月的数据"
                );
                return;
            }
            this.startSearch();
        },
        //获取活动信息列表
        async getActivities() {
            const { appType } = this;

            const data = await this.$request({
                url: `/api/report/order/getRedpacketActivityList4Tmall`,
                toast: false
            });
            this.activities = data || [];
            this.allActivities = data || [];
            /* eslint-disable */
            if (!this.activities.length) {
                this.status = "success";
                return;
            }

            this.$nextTick(() => {
                this.activityId = Number(this.$route.query.activityId);
                if (!this.activityId) {
                    this.activityId = this.activities[0].activityId;
                }

                this.getRewardActivityDetail();
            });
            /* eslint-enable */
        },
        //根据活动Id获取联动红包数据
        async getRewardActivityDetail() {
            let thisActivity = this.activities.find(
                v => v.activityId === this.activityId
            );

            try {
                const result = await this.$request({
                    url: "/api/report/order/getRedpacketCpaStat",
                    params: {
                        activityId: this.activityId
                    },
                    origin: true,
                    toast: false
                });

                if (result.code === 200) {
                    this.activity = result.data || {};
                    this.activity.startTime = thisActivity.startTime;
                    this.activity.endTime = thisActivity.endTime;
                    this.status = "success";
                    return;
                }
                this.$message.error("当前活动暂未产生订单，还请努力哦~");
                setTimeout(() => {
                    window.location.href = "/report/reward";
                }, 1000);
            } catch (e) {
                this.status = "error";
            }
        },
        //订单查询
        async getRedpacketOrderDetailReport() {
            const data = await this.$request({
                url: "/api/report/order/getRedpacketOrderDetailReportByParam",
                method: "post",
                data: this.getRedpacketDailyParams(this.rangeDate2)
            });
            const { maxCount, zhuankeReportOrderDetailList } = data || {};
            this.redPackOrder = zhuankeReportOrderDetailList;
            this.totalCount = maxCount;
        },

        startSearch() {
            this.formData.pageIndex = 1;
            //红包发放量
            if (`${this.activeIndex}` === "1") {
                this.getRedpacketDaily();
            }
            if (`${this.activeIndex}` === "2") {
                this.getRedpacketOrderDetailReport();
            }
        },

        destroyCountDown() {
            if (this.countDown) {
                try {
                    this.countDown._counter &&
                        clearInterval(this.countDown._counter);
                } catch (e) {
                    /**/
                }
            }
        },

        startCountDown() {
            this.destroyCountDown();

            if (this.activity.activityStatus !== 1) {
                return;
            }
            this.countDown = new Countdown({
                totalTime: this.activity.endTime - +new Date(),
                onUpdate: t => {
                    this.remainTime = t;
                },
                onStop: () => {
                    this.activity.activityStatus = 2;
                }
            });
        },
        //红包发放量
        async getRedpacketDaily() {
            const data = await this.$request({
                url: "/api/report/order/getRedpacketDailyStatByParam",
                method: "post",
                data: this.getRedpacketDailyParams(this.rangeDate1)
            });
            this.list = data.statList || [];
            this.totalCount = data.maxCount;
        },

        getRedpacketDailyParams(rangeDate) {
            return {
                startTime: +new Date(rangeDate[0]),
                endTime: getDayEnd(+new Date(rangeDate[1])),
                pageIndex: this.formData.pageIndex || 1,
                pageSize: this.formData.pageSize || 20,
                status: this.formData.settleStatus,
                positionId: this.formData.positionId
            };
        },

        handleSizeChange(size) {
            this.formData.pageIndex = 1;
            this.formData.pageSize = size;
            if (this.activeIndex == 1) {
                this.getRedpacketDaily();
            }
            if (this.activeIndex == 2) {
                this.getRedpacketOrderDetailReport();
            }
        },

        handleCurrentChange(p) {
            this.formData.pageIndex = p;
            if (this.activeIndex == 1) {
                this.getRedpacketDaily();
            }
            if (this.activeIndex == 2) {
                this.getRedpacketOrderDetailReport();
            }
        },

        handleActivityChange() {
            this.getRewardActivityDetail();
        },
        //导出订单
        showExportOrdersPrompt() {
            this.exportVisible = true;
        },

        getExportEmail(email) {
            this.exportOrders(email);
        },

        exportOrders(email) {
            let url = "/api/report/order/exportRedpacketOrder";
            this.$request({
                url,
                method: "post",
                data: {
                    email,
                    ...this.getRedpacketDailyParams(this.rangeDate2)
                },
                origin: true
            })
                .then(data => {
                    setTimeout(() => {
                        if (data.code === 200) {
                            this.showExportOftenAlert();
                            return;
                        }
                        this.showExportErrorAlert(data.desc || "");
                    }, 300);
                })
                .catch(() => {
                    this.showExportErrorAlert();
                });
        },

        showExportErrorAlert(errText) {
            const err = errText ? errText : "导出失败";
            this.$alert(err, "提示", {
                confirmButtonText: "确定"
            });
        },

        showExportOftenAlert() {
            this.$alert(
                "您下载的文件正在生成，请不要重复点击，文件生成后，将会以邮件形式发送给您",
                "提示",
                {
                    confirmButtonText: "确定"
                }
            );
        }
    }
};
</script>
<style lang="scss" scoped>
.el-menu.el-menu--horizontal {
    border: 0;
}
</style>

<style lang="scss" scoped>
.reward {
    min-height: 100%;
    position: relative;

    &-inner {
        background: #fff;
        padding: 16px;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

        h3 {
            margin-bottom: 10px;
        }
    }
    .reward-info {
        height: 40px;
        &-block {
            margin-right: 20px;
            display: inline-block;
            vertical-align: middle;
        }
        .reward-middle {
            height: 40px;
            line-height: 38px;
            vertical-align: middle;
        }
    }
    .reward-detail {
        margin-top: 20px;
        &-speical {
            margin: 20px 0;
        }
        .reward-amount {
            padding-right: 20px;
            padding-left: 20px;
            display: inline-block;
            border-right: 1px solid #ccc;
            &.un {
                border-right: 0;
            }
            .tip {
                font-size: 14px;
            }
            .money {
                margin-top: 10px;
                font-size: 32px;
                font-weight: bold;
                color: #ff1e32;
                text-align: center;
                .symbol {
                    font-size: 24px;
                }
            }
        }
    }
    .reward-form {
        position: relative;
        .export-btn {
            position: absolute;
            right: 0;
        }
    }
    .reward-page {
        margin-top: 20px;
        text-align: right;
    }

    .reward-expand-content .el-table__empty-block {
        background: #f5f5f5;
    }

    .reward-level {
        margin-top: 20px;
    }

    .rule {
        line-height: 1.8;
    }
}
</style>
